import React, {useRef} from "react";
import styled from "styled-components";
import {black3, brown1, greyText2} from "../../../oat-color-variables";
import {Button} from "../../../oat-ui";
import AnimateIn from "../../../oat-ui/animation/Animate";
import {
  defaultMaxWidthDesktop,
  descriptionFontSize,
  headerFontSize,
} from "../layoutVariables";
import {useNavigate} from "react-router-dom";
import {homePageBackground2} from "./styles/layoutStyles";
import {getWindowSize} from "../../../oat-window-size/getWindowSize";
import { useElementOnScreen } from "../../../oat-ui/hooks/useElementOnScreen";

const imageSize = 246;
const templates = [
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/vacation-templates.png",
    yOffset: 180,
    order: 1,
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/chart-template.png",
    yOffset: -100,
    order: 2
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/flower-pink-templates.png",
    yOffset: -200,
    order: 3,
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/paper-template.png",
    yOffset: -390,
    order: 1,
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/sun-n-moon-template.png",
    yOffset: 185,
    order: 2,
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/hiking-template.png",
    yOffset: -90,
    order: 3,
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/game-template.png",
    yOffset: -190,
    order: 1,
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/flower-template.png",
    yOffset: -340,
    order: 2,
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/23-bd-template.png",
    yOffset: 190,
    order: 3,
  },
  {
    image:
      "https://dnaeh50kru9rd.cloudfront.net/home-page/feature-templates/basket-template.png",
    yOffset: -80,
    order: 1,
  },
];

const TemplatesShowcase = () => {
  let navigate = useNavigate();
  const windowSize = getWindowSize();
  const ref = useRef(null);
  const onScreen = useElementOnScreen(ref);

  const linkToRegistration = () => {
    return navigate(`/register`);
  };

  return (
    <Layout>
      <Wrapper>
        <AnimateIn>
          <ContentSection>
            <Content>
              <h3>Join for Free</h3>
              <p>
                {/* Create fresh, beautiful stories and contents for all your social media channels in seconds. */}
                Create fresh, beautiful stories with our templates to make your
                work stand out from the crowd
              </p>

              <WrapButton>
                <Button styles={{ fontSize: 16 }}onClick={linkToRegistration}>Join Now</Button>
              </WrapButton>
            </Content>
          </ContentSection>
        </AnimateIn>
        <div
          style={{
            display: "block",
            height: "auto",
            transform:
              windowSize.width >= 850
                ? `scale(1)`
                : `scale(${(windowSize.width * 1) / 1000})`,
          }}
        >
          <WrapTemplates ref={ref}>
            {templates &&
              templates.map((template, i) => (
                <Template
                  key={i}
                  style={{
                    transform: `translateY(${template.yOffset}px)`,
                    zIndex: template.image === "" ? -1 : 1,
                    opacity: onScreen ? 1 : 0, 
                    transition: `0.7s all ease-in-out ${template.order * 300}ms`
                  }}
                >
                  <Image src={template.image} loading="lazy" /> 
                </Template>
              ))}
          </WrapTemplates>
        </div>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 2.4em 0 0;
  background: ${homePageBackground2};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: ${defaultMaxWidthDesktop}px;
`;

const ContentSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2.8em 0;
  h3 {
    font-size: 32px;
    font-weight: 700;
    color: ${brown1};
    padding-bottom: 0.75em;
  }
  p {
    font-size: 19px;
    color: ${black3};
    text-align: center;
    line-height: 1.6em;
    max-width: 90%;
    padding: 1em 0;
  }
  @media only screen and (min-width: 901px) {
    max-width: 510px;
  }
  @media only screen and (min-width: 601px) and (max-width: 900px) {
    max-width: 45%;
    h3 {
      font-size: 28px;
    }
    p {
      font-size: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
    max-width: 48%;
    padding: 0 0.8em;
    p {
      max-width: 60%;
      font-size: ${descriptionFontSize.mobile};
    }
  }
`;

const WrapButton = styled.div`
  height: 38px;
  margin-top: 1.1em;
  width: 120px;
`;

const WrapTemplates = styled.div`
  display: grid;
  grid-template-columns: repeat(4, ${imageSize}px);
  position: relative;
  bottom: -1.2rem;
  left: 5.5rem;
  max-height: 362px;
  transform: rotateX(60deg) rotateY(0deg) rotateZ(39deg) translateY(-2rem)
    translateX(-2rem);
  padding-bottom: 100px;
  @media only screen and (max-width: 600px) {
    bottom: 0;
    padding-bottom: 0px;
    transform: rotateX(60deg) rotateY(0deg) rotateZ(39deg) translateY(3rem)
      translateX(-2rem) scale(1.5);
  }
`;

const Template = styled.div`
  display: grid;
  width: 100%;
  margin-right: 0.95em;
  margin-bottom: 0.7em;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  transition: 1s all ease-in-out;
  padding: 0 16px 0 0;
  box-sizing: border-box;
  @media only screen and (min-width: 601px) {
    &:hover {
      transform: scale(0.9);
    }
  }
`;

export default TemplatesShowcase;
