import React from "react";
import styled from "styled-components";
import {brown1, greyText1, greyText2} from "../../../oat-color-variables";
import {
  defaultMaxWidthDesktop,
  defaultMaxWidthLaptop,
  layoutPadding,
} from "../layoutVariables";

const FeaturesShowcase2 = () => {
  return (
    <Layout>
      <Wrapper>
        <HomeHeader>Essentials</HomeHeader>
        <Image
          alt=""
          loading="lazy"
          src="https://images.unsplash.com/photo-1524505905671-960c9b37af28?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1557&q=80"
        />
        <ContentSection>
          <Contents>
            <Content>
              <h3>Ease of Use</h3>
              <p></p>
            </Content>
            <Content>
              <h3>Collaboration</h3>
              <p>Chat, comments and design in real time and track progresses</p>
            </Content>
            <Content>
              <h3>Sharing</h3>
              <p>
                Export as images, pdf or just drop a link for quicker access
              </p>
            </Content>
            <Content>
              <h3>Stay on Brand</h3>
              <p>
                Keep colors and fonts consistent and professional, in align with
                your brand
              </p>
            </Content>
          </Contents>
        </ContentSection>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3.4em;
  box-sizing: border-box;
  @media only screen and (min-width: 601px) {
    padding: 2.8rem ${layoutPadding.desktop};
  }
  @media only screen and (max-width: 600px) {
    padding: 2.2em ${layoutPadding.mobile};
    margin-bottom: 0em;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: ${defaultMaxWidthLaptop}px;
  @media only screen and (min-width: 1000px) and (max-width: 1179px) {
    max-width: 94%;
  }
  @media only screen and (min-width: 1180px) and (max-width: 1500px) {
    max-width: ${defaultMaxWidthLaptop}px;
  }
  @media only screen and (min-width: 1501px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
`;

export const HomeHeader = styled.h1`
  // color: ${brown1};
  color: #8484ff;
  font-size: 1.5em;
  padding: 0.5em 0 1.5em;
  span {
    font-size: 1.2rem;
    padding-right: 12px;
    color: #8484ff;
  }
  @media only screen and (min-width: 1500px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 1.6em;
    padding: 0.25em 0 0.5em;
  }
`;

const ContentSection = styled.div``;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.6em;
  object-fit: cover;
`;

const Contents = styled.div`
  display: flex;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 0 1.2em 0 0;
  @media only screen and (max-width: 999px) {
    padding-bottom: 1.8em;
  }
  h3 {
    color: ${greyText1};
    font-size: 1em;
    font-weight: 700;
    padding-bottom: 0.3em;
    @media only screen and (max-width: 600px) {
      font-size: 1.2em;
    }
  }
  p {
    font-size: 0.9em;
    color: ${greyText2};
    @media only screen and (max-width: 600px) {
      font-size: 1.1em;
    }
  }
`;

const WrapImage = styled.div`
  width: 100%;
  height: 100%;
  background: #e3e3ff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 1.4em;
`;

export default FeaturesShowcase2;
