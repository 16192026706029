import {useRef} from "react";
import {useElementOnScreen} from "../hooks/useElementOnScreen";

const AnimateIn = ({children, style, flex, speed}) => {
  const ref = useRef(null);
  const onScreen = useElementOnScreen(ref);
  return (
    <div
      ref={ref}
      style={{
        ...style,
        display: "flex",
        flex,
        opacity: onScreen ? 1 : 0,
        transform: onScreen ? "translate3d(0px, 0px, 0)" : "translate3d(0px, 2rem, 0)",
        transition: `all ${speed}s cubic-bezier(.35,.2,.67,.93) 0.3s`,
      }}
    >
      {children}
    </div>
  );
};

AnimateIn.defaultProps = {
  flex: 1,
  speed: 0.4
};

export default AnimateIn;
