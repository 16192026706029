import React from 'react';
import styled from 'styled-components';
import {layoutPadding} from "../layoutVariables";
import { HomeHeader } from './FeaturesShowcase2';
import { maxWidthPanel } from './FeatureList';
import AnimateIn from '../../../oat-ui/animation/Animate';
import { embedWindowPanelSize } from './EmbedTemplate';
import { Link } from 'react-router-dom';
import AvailableServices from '../../bespokeServices/AvailableServices';
import { black3, blue1, greyText2 } from '../../../oat-color-variables';

const BespokeServices = () => {
    return (
        <Layout>
            <Section>
                <AnimateIn style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <HomeHeader className="content-title">
                        Bespoke Services
                    </HomeHeader>
               
                    <Content>
                        <div className="content-description">
                            Craft bespoke illustrations and graphics that resonate with your audience, driving sales and leaving a lasting impression. 
                        </div>
                    </Content>
                </AnimateIn>
                
                <AnimateIn>
                    <Services>
                        <a href="/bespoke-services?type=illustrations" target='_blank' className="services-item">
                            <img loading="lazy" src="https://dnaeh50kru9rd.cloudfront.net/home-page/bespoke-services/illustration-services-3.jpg" alt="oat-all-illustrations" />
                            <h3>Illustration Services</h3>
                        </a>

                        <a href="/bespoke-services?type=presentation-deck" target='_blank' className="services-item">
                            <img loading="lazy" src="https://dnaeh50kru9rd.cloudfront.net/home-page/bespoke-services/sales-pitch-decks-services.jpg" alt="oat-all-deck-services" />
                            {/* src="https://dnaeh50kru9rd.cloudfront.net/home-page/main-header/sales-pitch-decks-services.jpg"  */}
                            <h3>Sales Deck Services</h3>
                        </a>

                        {/* <div className="services-item">
                            <img src="https://images.unsplash.com/photo-1620503374956-c942862f0372?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="oat-all-sales-deck" />
                            <h3>Pitch Deck</h3>
                        </div> */}
                    </Services>
                </AnimateIn>
            </Section>
        </Layout>
    )
}

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #f5f7fc;
    padding: 5.2em ${layoutPadding.desktop} 5.2em;
    box-sizing: border-box;
    @media only screen and (min-width: 601px) {
        padding: 5.5rem ${layoutPadding.desktop} 6.8rem;
    }
    @media only screen and (max-width: 600px) {
        padding: 5.2rem ${layoutPadding.mobile} 2.6rem;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${maxWidthPanel};
`

const Content = styled.div`
    display: flex;
    flex: 1.5;
    flex-direction: column;
    max-width: 65%;
    margin-bottom: 48px;
    line-height: 32px;
    color: ${black3};
    .content-description {
        line-height: 32px;
        text-align: center;
        font-size: 19px;
        color: ${greyText2};
        @media only screen and (max-width: 600px) {
            font-size: 17px;
        }
    }
    @media only screen and (max-width: 768px) {
        max-width: 85%;
    }
    @media only screen and (max-width: 600px) {
        max-width: 90%;
    }
`;

const Services = styled.div`
    display: flex;
    flex: 1;
    .services-item {
        padding: 20px;
        box-sizing: border-box;
        border: 1px solid #c7c4d4;
        border-radius: 14px;
        cursor: pointer;
        text-decoration: none;
        flex: 1;
        img {
            max-width: 100%;
            border-radius: 12px;
            margin-bottom: 14px;
        }
        h3 {
            font-size: 16px;
            color: #5f5f67;
        }
        @media only screen and (max-width: 600px) {
            margin-bottom: 32px;
        }
        @media only screen and (min-width: 601px) {
            margin: 24px;
            &:first-child {
                margin: 24px 24px 24px 0;
            }
            &:last-child {
                margin: 24px 0 24px 24px;
            }
            &:hover {
                h3 {
                    color: ${blue1};
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    } 
`

const Item = styled(Link)`
 text-decoration: none;
 color: inherit;
`

export default BespokeServices