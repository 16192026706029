import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {black2, paleBlue5, primaryBlue} from "../../../oat-color-variables";
import {Facebook, LinkedIn, Twitter} from "../../../oat-svg-icons";
import LayoutHoc from "../../layout/LayoutHoc";
import {getWindowSize} from "../../../oat-window-size/getWindowSize";

const layoutMaxWidth = "62rem";
const contentMaxWidth = "42rem";
const borderRadius = 12;

const BlogDetails = () => {
  const contentLayoutRef = useRef();
  const windowSize = getWindowSize();
  const [coverImgSize, setCoverImgSize] = useState({width: 0, height: 0});
  const [coverImgLoaded, setCoverImgLoaded] = useState(false);
  const [embedSize, setEmbedSize] = useState({width: 0, height: 0});
  const [panelWidth, setPanelWidth] = useState(0);

  useEffect(() => {
    const coverWidth = (937 * windowSize.width) / 1439;
    setCoverImgSize({
      width: coverWidth,
      height: (coverWidth * 527) / 937,
    });

    // let embedWidth = windowSize.width - 80;
    // if (windowSize.width > 850) {
    //   embedWidth = windowSize.width - windowSize.width / 3.2;
    // } else if (windowSize.width <= 600) {
    //   embedWidth = windowSize.width - 30;
    // }
    // setEmbedSize({
    //   width: embedWidth,
    //   height: (embedWidth * 1080) / 1920,
    // });
  }, [windowSize.width]);

  const imageOnLoad = () => {
    setCoverImgLoaded(true);
  };

  useEffect(() => {
    setPanelWidth((70 * window.innerWidth) / 100)
  }, [windowSize.width])

  return (
    <LayoutHoc>
      <Layout>
        <Wrapper>
          <HeaderSection>
            <Title>Crafting Beautiful Slides</Title>
            <SecondaryTitle>
              The choice of fonts, colors, consistency, and layouts plays a pivotal role in creating slides that are not only visually appealing but also enhance the overall message to communicate information effectively.
            </SecondaryTitle>
            <WrittenBy>
              <UserAvatar
                src="https://images.unsplash.com/photo-1641792586627-fc501ff644b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=828&q=80"
                alt=""
              />
              <AuthorName>
                by <strong>Kenny Khant</strong>
              </AuthorName>
            </WrittenBy>
          </HeaderSection>

          <WrapCoverImage>
            <CoverImage
              onLoad={imageOnLoad}
              coverSize={coverImgSize}
              src="https://images.unsplash.com/photo-1555191525-74c91fc116c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            />
          </WrapCoverImage>

          <WrapContent>
            <Content
              ref={contentLayoutRef}
              contentEditable={false}
              embedSize={embedSize}
              dangerouslySetInnerHTML={{
                __html: `
                <h3>Typography</h3>
                <p>
                  Fonts are more than just words on a slide. They evoke emotions and convey personality that can influence how your audience perceives your message. 
                </p>
                <br>
                <p>
                  Timeless and traditional fonts convey a sense of stability and authority. Modern and clean exude a contemporary and straightforward vibe, while elegant and creative fonts should be used sparingly to maintain readability.
                </p>
                <br>
                <p>
                  Tailor your font choice to your audience and the purpose of your presentation. For formal settings, opt for classic fonts; for creative presentations, experiment with more expressive fonts. Ensure that your chosen font is readable, and reflects your brand's values. Test fonts at various sizes to guarantee clarity.
                </p>
                <br>
                <p>
                  Another thing to keep in mind is regarding with pairing techniques, which add visual interest while maintaining a cohesive look.
                </p>     
                <ul>   
                  <li>Contrast: Combine serif and sans-serif fonts for contrast. This contrast can be in style, weight, or both.</li>
                  <li>Similarity: Pair fonts with similar characteristics for a harmonious look. This could involve similar x-heights or overall styles.</li>
                  <li>Hierarchy: Establish a hierarchy by using different fonts for headings and body text. Ensure that the hierarchy is clear and enhances the flow of information.</li>
                </ul>
                <br>
                <img src="https://oat-users.s3.amazonaws.com/1701662999133-999383.jpeg"/>

                <h3>Whitespace</h3>
                <p>
                  Whitespace, also known as negative space, in your presentation design is crucial for enhancing readability and overall visual appeal. It helps prevent visual clutter, providing a breathing room that makes it easier for your audience to read and understand your content.
                </p>
                <br>
                <p>
                  Moreover, whitespace helps focus your audience's attention. By strategically incorporating empty spaces, you can guide the viewer's gaze to key elements or messages on your slides.
                </p>
                <br>
                <p>
                  Lastly, whitespace contributes to a balanced and harmonious composition, preventing information overload and ensuring your slides aren’t visually overwhelming. 
                </p>
                <br>
                
                <img src="https://oat-users.s3.amazonaws.com/1701581440655-439859.png"/>
                <span>Apple's masterful utilization of whitespace on their website</span>
                <br>

                <h3>Consistency is Key</h3>
                <p>
                  If you are running a company, your brand identity should shine through every slide. Logos, fonts and brand colors should align seamlessly with your organization's visual identity.
                  This reduces a cognitive load, where the audience can focus on the content rather than being distracted by abrupt design changes.
                </p>
                <br>
                <p>
                  Consistent repetition of elements and layout patterns serves to fortify the structure and visual hierarchy, creating a cohesive and memorable design.
                </p>
                <br>

                <h3>Layouts & Structures</h3>
                <p>
                  Thoughtful orchestration of layouts and structures becomes a powerful tool in transforming complex ideas into digestible components that resonate with the audience.
                </p>
                <br>
                <p>
                  The judicious use of headers, subheadings, and consistent formatting creates a visual hierarchy that guides the audience through a seamless flow of information from one point to another.
                </p>
                <br>
                <p>
                  Grouping related elements together is another technique that not only acts as a visual aesthetic but also aids in improving readability and facilitates effortless scanning for the audience.
                </p>
                <br>
                <p>
                  The fusion of purposeful layouts and structures in design empowers communicators to convey their message with impact, fostering engagement and retention among their audience.  
                </p>
                <br>

                <div class="wrap-iframe">
                  <iframe title="" src="https://oatall.com/p/Ko9ZhflesAL?embed=true" allow="fullscreen 'self' https://oatall.com" width="345"
                    height="243" style="border:0">
                  </iframe>
                </div>

                <br>
                <br>
                <h3>Recap</h3>
                <p>
                  Whitespace is the luxury of simplicity, inviting users to engage without the noise of visual overload.
                </p>
                <br>

                <p>
                  Consistency in font size and style contributes to an accessible and easy-to-read presentation.
                  The repetitive use of colors and fonts creates a sense of professionalism and polished look for your presentation.
                </p>
                <br>

                <p>
                  Employ strategic layouts and logical groupings to achieve a harmonious visual story.
                </p>
                <br>
                <br>

                <p>
                  Apply these principles to captivate your audience and leave a lasting impression.
                </p>
                <br>
              `,
            }}
            ></Content>
          </WrapContent>

          {/* <ShareSocialMedia>
            <WrapSvg>{Twitter}</WrapSvg>
            <WrapSvg>{LinkedIn}</WrapSvg>
            <WrapSvg>{Facebook}</WrapSvg>
          </ShareSocialMedia> */}
        </Wrapper>
      </Layout>
    </LayoutHoc>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 24px 58px;
  @media only screen and (max-width: 849px) {
    margin: 20px 20px 38px;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapContent = styled.div`
  padding: 0 calc(20vw - 130px) 0px;
  margin-bottom: 215px;
  @media only screen and (min-width: 900px) {
    padding: 0 calc(20vw - 10rem) 0px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 22px 0;
  max-width: ${contentMaxWidth};
  color: ${black2};
  line-height: 30px;
  letter-spacing: 0.2px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 32px;
  font-weight: 800;
  color: ${primaryBlue};
  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
`;

const SecondaryTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
`;

const WrapCoverImage = styled.div`
  max-width: 100%;
  margin-bottom: 18px;
  position: relative;
`;

const CoverImage = styled.img`
  width: ${({coverSize}) => coverSize.width}px;
  height: ${({coverSize}) => coverSize.height}px;
  max-width: 100%;
  border-radius: 16px;
  z-index: 1;
  background: ${paleBlue5};
  object-fit: cover;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;

const Content = styled.div`
  position: relative;
  font-size: 1.2rem;
  color: ${black2};
  line-height: 34px;
  letter-spacing: 0.05px;
  max-width: ${layoutMaxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    width: 100%;
    color: #52545b;
    max-width: ${contentMaxWidth};
    margin: 26px 0;
    font-size: 1.7rem;
  }
  p {
    max-width: ${contentMaxWidth};
  }
  span {
    color: grey;
    font-size: 18px;
  }
  ul, ol {
    max-width: ${contentMaxWidth};
    margin: 26px 0;
    li {
      margin-bottom: 12px;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    margin: 22px 0;
    background: ${paleBlue5};
    transition: opacity 0.4s;
    box-shadow: 0px 0px 3px 0px #e0d9d9;
  }
  .wrap-iframe {
    // margin: 3rem 0;
    position: relative;
    width: 120%;
    max-width: 1064px;
    overflow: hidden;
    border-radius: ${borderRadius}px;
    box-shadow: 0px 0px 3px 0px #e0d9d9;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: ${borderRadius}px;
      overflow-y: hidden;
    }
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
    &::after {
      display: block;
      content: "";
      padding-bottom: 62.87%;
      @media only screen and (max-width: 650px) {
        padding-bottom: 69.75%;
      }
    }
  }
  @media only screen and (max-width: 849px) {
    font-size: 1.1rem;
    h3 {
      margin: 14px 0;
      font-size: 1.3rem;
    }
  }
`;

const ShareSocialMedia = styled.div`
  position: relative;
  display: flex;
  @media only screen and (max-width: 899px) {
    margin-top: 42px;
  }
  @media only screen and (min-width: 900px) {
    position: fixed;
    height: 108px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    bottom: ${window.innerHeight / 2 - 54}px;
    right: 30px;
  }
`;

const WrapSvg = styled.div`
  width: 24px;
  height: 24px;
  fill: #5b5770;
  @media only screen and (min-width: 900px) {
    padding-bottom: 14px;
    cursor: pointer;
  }
  @media only screen and (max-width: 899px) {
    padding-right: 22px;
  }
`;

const WrittenBy = styled.div`
  display: flex;
  margin: 22px 0;
`;

const AuthorName = styled.div`
  font-size: 0.95rem;
`;

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 22px;
`;

export default BlogDetails;
