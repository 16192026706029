import styled from "styled-components";
import {black2, black3, brown1} from "../../../../oat-color-variables";

export const WrapLayoutOne = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.8rem;
  column-gap: 1.8rem;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const LayoutOne = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.3rem 1.3rem 0 1.3rem;
  box-shadow: 0px 0px 1px 2px #f4f4f4;
  border-radius: 15px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 28px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  h3 {
    font-size: 1.25rem;
    color: ${brown1};
    padding-right: 8px;
  }
  img {
    width: 42px;
    height: auto;
    max-width: 100%;
    @media only screen and (max-width: 600px) {
      width: 44px;
    }
  }
  @media only screen and (max-width: 600px) {
    h3 {
      font-size: 1.15rem;
    }
  }
`;

export const WrapImage = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex: 2;
  width: 100%;
  img {
    max-width: 100%;
  }
  @media only screen and (max-width: 600px) {
    max-height: 275px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  font-size: 1.05rem;
  line-height: 1.5;
  color: ${black3};
  @media only screen and (max-width: 600px) {
    padding: 0px;
    p {
      padding-bottom: 8px;
      font-size: 1.02rem;
      line-height: 1.45;
      color: ${black2};
    }
  }
`;
