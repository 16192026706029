import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Container} from "./styles/layoutStyles";
import {
  Content,
  LayoutOne,
  WrapLayoutOne,
  Header,
} from "./styles/layoutOneStyles";
import AnimateIn from "../../../oat-ui/animation/Animate";
import {defaultMaxWidthLaptop, layoutPadding} from "../layoutVariables";
import { getWindowSize } from "../../../oat-window-size/getWindowSize";

const DisplayItem = ({item, animationOrder, animationType, isActive}) => {
  const [currentAnimationOrder, setCurrentAnimationOrder] = useState(0);
  const [previewAnimateStyles, setPreviewAnimateStyles] = useState({});
  const previewAnimation = true;
  useEffect(() => {
    const visibility = {
      opacity: 0,
      visibility: "hidden",
      transition: "0.5s all ease-out",
    };

    if (previewAnimation && item) {
      if (animationType === "bottom-to-top") {
        setPreviewAnimateStyles({
          ...visibility,
          transform: "translate(0px, 80px)",
        });
      } else if (animationType === "top-to-bottom") {
        setPreviewAnimateStyles({
          ...visibility,
          transform: "translate(-80px, 0px)",
        });
      } else if (animationType === "right-to-left") {
        setPreviewAnimateStyles({
          ...visibility,
          transform: "translate(80px, 0px)",
        });
      }

      if (isActive) {
        setTimeout(() => {
          setPreviewAnimateStyles({
            transform: "translate(0px, 0px)",
            opacity: 1,
            visibility: "visible",
            transition:
              //   animationOrder === 2
              //     ? "1s all ease-out 5s"
              // :
              "0.5s all ease-out",
          });
        }, 1500);
      } else if (animationOrder >= item?.order) {
        setPreviewAnimateStyles({
          transform: "translate(0px, 0px)",
          opacity: 1,
          visibility: "visible",
          transition: "0.5s all ease-out",
        });
      }
    }
  }, [currentAnimationOrder, previewAnimation, isActive]);

  return (
    <WrapSvg style={{...previewAnimateStyles}}>
      <img src={item?.file} />
    </WrapSvg>
  );
};

const AnimationAndCollaboration = () => {
  const items = [
    {
      file: "https://oat-default-init.s3.amazonaws.com/home-page/character-nya-300x.png",
      position: "",
      order: 1,
      animationType: "bottom-to-top",
    },
    {
      file: "https://oat-default-init.s3.amazonaws.com/home-page/japanese-character-300x.png",
      position: "",
      order: 3,
      animationType: "right-to-left",
    },
  ];

  const [animationOrder, setAnimationOrder] = useState(0);

  useEffect(() => {
    const intervalDuration = animationOrder === items.length - 1 ? 5500 : 1200;
    const interval = setInterval(() => {
      setAnimationOrder((prevOrder) =>
        prevOrder === items.length - 1 ? 0 : prevOrder + 1
      );
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [animationOrder]);

  const windowSize = getWindowSize();

  return (
    <Layout>
      <Container style={{flex: 1}}>
        {/* <WrapHeader>
          <AnimateIn>
            <HomeHeader>Designing should be fun</HomeHeader>
          </AnimateIn>
        </WrapHeader> */}

        <WrapLayoutOne>
          <AnimateIn style={{flex: 1}}>
            <LayoutOne
              index={1}
              style={{
                display: "flex",
                background: `linear-gradient(rgb(243, 243, 255), rgb(255 246 247))`,
                minHeight: windowSize.width <= 600 ? 'auto' : 420,
                overflow: "hidden",
              }}
            >
              <Content>
                <Header>
                  <h3>Animation</h3>
                </Header>

                <p style={{ marginBottom: windowSize.width <= 600 ? 38 : undefined }}>
                  Animate all fields on slide load or by each block individually
                  during your presentation.
                </p>
              </Content>

              <WrapAnimationItems style={{ marginBottom: windowSize.width < 600 ? 30 : undefined }}>
                <div>
                  <DisplayItem
                    animationOrder={0}
                    animationType={"none"}
                    isActive={false}
                    totalLength={items.length}
                    item={{
                      file: "https://dnaeh50kru9rd.cloudfront.net/home-page/character-A-300x.png",
                    }}
                  />
                </div>
                {items &&
                  items.map((item, i) => (
                    <div key={i}>
                      <DisplayItem
                        item={item}
                        key={i}
                        animationOrder={animationOrder}
                        animationType={item.animationType}
                        isActive={i === animationOrder}
                        totalLength={items.length}
                      />
                    </div>
                  ))}
              </WrapAnimationItems>
            </LayoutOne>
          </AnimateIn>

          <AnimateIn style={{flex: 1}}>
            <LayoutOne
              index={2}
              style={{
                background: `linear-gradient(rgba(204, 225, 244, 0.1), rgb(247 237 255))`,
                minHeight: windowSize.width <= 600 ? 'auto' : 420,
              }}
            >
              <Content style={{marginBottom: "3.5rem"}}>
                <Header>
                  <h3>Collaborate as Team</h3>
                </Header>

                <p>
                  Seamlessly collaborate & share ideas to stay on top of
                  productivity.
                </p>

                <WrapImage>
                  <img
                    src="https://dnaeh50kru9rd.cloudfront.net/home-page/teamwork/team-work-800.png"
                    loading="lazy"
                  />
                </WrapImage>
              </Content>
            </LayoutOne>
          </AnimateIn>
        </WrapLayoutOne>
      </Container>
    </Layout>
  );
};

const WrapSvg = styled.div`
  height: 130px;
  @media only screen and (min-width: 601px) {
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    img {
      width: 100%;
    }
  }
`;

export const Layout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  @media only screen and (min-width: 601px) {
    padding: 6.8rem ${layoutPadding.desktop};
  }
  @media only screen and (max-width: 600px) {
    padding: 2em ${layoutPadding.mobile};
    margin-bottom: 0em;
  }
`;

const WrapAnimationItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const WrapImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% 4.5%;
  box-sizing: border-box;
  img {
    max-width: 100%;
  }
`;

export default AnimationAndCollaboration;
