import React, { useEffect, useRef, useState } from "react";
import {maxWidthPanel} from "./FeatureList";
import AnimateIn from "../../../oat-ui/animation/Animate";
import styled from "styled-components";
import {contentListStyles} from "./styles/layoutStyles";
import Check from "../../../oat-svg-icons/icons/Check";
import { CheckSvg } from "./core/homeCoreStyles";
import {
  defaultMaxWidthDesktop,
  defaultMaxWidthLaptop,
} from "../layoutVariables";
import { getWindowSize } from "../../../oat-window-size/getWindowSize";
import { black3, brown1 } from "../../../oat-color-variables";

const LibraryFeature = () => {
  const windowSize = getWindowSize();
  const layoutRef = useRef();
  const [minSectionWidth, setMinSectionWidth] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (layoutRef?.current?.offsetWidth) {
      const width = layoutRef?.current?.offsetWidth;
      setMinSectionWidth(width >= 1240 ? 1240 : width / 2);
    }
  }, []);

  const onImageLoaded = () => {
    setImageLoaded(true);
  }

  return (
    <Layout ref={layoutRef}>
      <div>
        {/* <WrapHeader>
          <AnimateIn>
            <HomeHeader>Workspace Library</HomeHeader>
          </AnimateIn>
        </WrapHeader> */}

        <Wrapper>
          <AnimateIn>
            <Item style={{ minWidth: !imageLoaded ? minSectionWidth : undefined }}>
              <WrapImage>
                <img
                  src={
                    window.innerWidth < 1470 && window.innerWidth > 450
                      ? "https://dnaeh50kru9rd.cloudfront.net/home-page/dashboard-and-magnifying-glass/dashboard-and-magnifying-glass-850.png"
                      : window.innerWidth >= 1470
                      ? "https://dnaeh50kru9rd.cloudfront.net/home-page/dashboard-and-magnifying-glass/dashboard-and-magnifying-glass-1000.png"
                      : "https://dnaeh50kru9rd.cloudfront.net/home-page/dashboard-and-magnifying-glass/dashboard-and-magnifying-glass-1400.png"
                  }
                  style={{filter: "drop-shadow(#ededed 1px 2px 5px)"}}
                  alt="passcode-protected"
                  loading="lazy"
                  onLoad={onImageLoaded}
                />
              </WrapImage>
            </Item>
          </AnimateIn>

          <AnimateIn>
            <Item style={{ 
                maxWidth: windowSize.width > 1000 ? "80%" : windowSize.width > 1500 ? '90%' : undefined ,
                minWidth: !imageLoaded ? minSectionWidth : undefined
              }}>
              <div style={{display: "flex"}}>
                <ContentList style={{marginLeft: windowSize.width > 1000 ? 12 : undefined}}>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <WrapContent>
                        <h3>Store Contents in Folders</h3>
                        <p>
                          Public / Private folders to restrict access to your contents among workspace members
                        </p>
                      </WrapContent>
                    </div>
                  </>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <WrapContent>
                        <h3>Library to Store Media Assets</h3>
                        <p>
                          Centrally store fonts, images and templates for your members
                          to easily reuse
                        </p>
                      </WrapContent>
                    </div>
                  </>
                  <>
                    <div className="content-list-item">
                      <CheckSvg>{Check}</CheckSvg>
                      <WrapContent>
                        <h3>Invite Guest Members</h3>
                        <p>
                          Share templates with non-workspace members to collaborate on projects
                        </p>
                      </WrapContent>
                    </div>
                  </>
                </ContentList>
              </div>
            </Item>
          </AnimateIn>
        </Wrapper>
      </div>
    </Layout>
  );
};

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 901px) {
    padding: 2.3rem 1.2rem 0;
    max-width: ${defaultMaxWidthLaptop + 200}px;
  }
  @media only screen and (max-width: 900px) {
    padding:  0 0 2em 0;
    margin-bottom: 0em;
    max-width: 100%;
  }
  @media only screen and (min-width: 1500px) {
    max-width: ${defaultMaxWidthDesktop + 100}px;
  }
`;

const WrapImage = styled.div`
  img {
    max-width: 100%;
    @media only screen and (min-width: 901px) {
      transform: scale(1.1);
      transform-origin: 120% 50%;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    flex-direction: column-reverse;
  }
`;

const Item = styled.div`
  display: flex;
  flex: 1;
  margin: 0em 0em 2.4rem 0rem;
  @media only screen and (min-width: 901px) {
    align-items: center;
    margin: 0em 0em 2.4rem 1rem;
  }
`;

const WrapContent = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 17px;
    margin-bottom: 15px;
    color: ${brown1};
  }
  p {
    font-size: 16px;
    margin-bottom: 6px;
    color: ${black3};
    line-height: 28px;
  }
  @media only screen and (min-width: 900px) {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 17px;
    }
  }
`

const ContentList = styled.div`
  ${contentListStyles}
`;

export default LibraryFeature;
