import React from "react";
import styled from "styled-components";
import {primaryPurple} from "../../../../../oat-color-variables";
import {useParams} from "react-router-dom";

const EmptyTemplates = ({message, smallText}) => {
  const {folderID} = useParams();
  return (
    <Wrapper>
      <WrapSvg>
        <img
          src="https://oat-all-media-storage.s3.amazonaws.com/home-page/empty+folder+icon/empty+folder+icon+v4.svg"
          alt=""
        />
      </WrapSvg>
      <Message>
        {folderID === "library-templates"
          ? "Reusable templates for your team"
          : message}
      </Message>
      <SmallText>{smallText}</SmallText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const WrapSvg = styled.div`
  width: 175px;
  height: 175px;
`;

const Message = styled.div`
  color: ${primaryPurple};
  font-size: 1.35rem;
  padding: 12px 0 7px;
`;

const SmallText = styled.p`
  color: #5a5656;
  font-size: 1rem;
  width: 340px;
  text-align: center;
  margin-top: 8px;
  @media only screen and (max-width: 849px) {
    width: 100%;
  }
`;

EmptyTemplates.defaultProps = {
  message: "This folder is empty.",
  smallText: "Start creating new contents.",
};

export default EmptyTemplates;
