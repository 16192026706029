import React from "react";
import styled from "styled-components";
import {Modal} from "../../../../../oat-ui";

const ShowAddTempProfile = ({close}) => {
  return (
    <Modal width={550} height={370} close={close}>
      <Wrapper>
        <Content>
          Add template to your profile by going to its location and click add
          profile.
        </Content>
        <Image src="https://images.unsplash.com/photo-1646041292771-3c9140a159f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80" />
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  padding: 12px;
  box-sizing: border-box;
`;

const Content = styled.div`
  font-size: 1rem;
  padding: 12px 0;
`;

const Image = styled.img`
  max-width: 100%;
  height: 300px;
  object-fit: cover;
`;

export default ShowAddTempProfile;
