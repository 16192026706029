import React from "react";
import {connect} from "react-redux";
import styled, {css} from "styled-components";
import {getWindowSize} from "../../../oat-window-size/getWindowSize";
import {selectThemeBuilder} from "../../store/selectors/template/theme.selector";
import SelectedShape from "../editDesign/canvasPanel/fields/shapes/SelectedShape";
import ThemeBuilderBarChart from "./panelTypes/charts/ThemeBuilderBarChart";
import ThemeBuilderStackedBar from "./panelTypes/charts/ThemeBuilderStackedBar";
import {Overlay} from "../editDesign/canvasPanel/fields/image/ImageField";
import tinycolor from "tinycolor2";

const ThemeCanvas = ({themeInfo, selectedTab}) => {
  const {width} = getWindowSize();
  const {bg} = themeInfo;
  const backgroundStyle = {
    background: !bg.gradient
      ? bg.bg1
      : bg.type !== "center"
      ? `linear-gradient(${bg?.bgAng}deg, ${bg?.bg1}, ${bg?.bg2})`
      : `radial-gradient(${bg?.bg1}, ${bg?.bg2})`,
  };

  const imageStyles = {
    shadowColor: themeInfo["image"]?.shadowColor,
    overlayColor: themeInfo["image"]?.overlayColor,
  };

  const shapeStyles = {
    color1: themeInfo["shape"]?.color1,
    color2: themeInfo["shape"]?.color2,
    gradientAngle: themeInfo["shape"]?.gradientAngle,
    strokeWidth: themeInfo["shape"]?.strokeWidth,
    stroke: themeInfo["shape"]?.stroke,
  };

  const barChartStyles = {
    rotate: 0,
    showBackground: themeInfo["chart"]?.showBackground,
    background: themeInfo["chart"]?.background,
    gridColor: themeInfo["chart"]?.gridColor,
    labelColor: themeInfo["chart"]?.labelColor,
    valueColor: themeInfo["chart"]?.valueColor,
    opacity: 1,
    scale: 1,
    fontFamily: themeInfo["chart"]?.fontFamily,
    barSize: 32,
    axisColor: themeInfo["chart"]?.axisColor,
  };

  const stackedBarChartStyles = {
    rotate: 0,
    showBackground: themeInfo["chart"]?.showBackground,
    background: themeInfo["chart"]?.background,
    gridColor: themeInfo["chart"]?.gridColor,
    labelColor: themeInfo["chart"]?.labelColor,
    valueColor: themeInfo["chart"]?.valueColor,
    opacity: 1,
    scale: 1,
    fontFamily: themeInfo["chart"]?.fontFamily,
    barSize: 32,
    axisColor: themeInfo["chart"]?.axisColor,
  };

  const barContent = {
    color: themeInfo["chart"].color1,
    data: [
      {
        x: "Jelly",
        value: 30,
        width: 18,
        color: themeInfo["chart"]?.colorSet[0],
      },
      {
        x: "Smoothie",
        value: 70,
        width: 39,
        color: themeInfo["chart"]?.colorSet[0],
      },
      {
        x: "Creamy",
        value: 113,
        width: 33,
        color: themeInfo["chart"]?.colorSet[0],
      },
      {
        x: "Cookie",
        value: 42,
        width: 20,
        color: themeInfo["chart"]?.colorSet[0],
      },
      {
        x: "Berry",
        value: 150,
        width: 21,
        color: themeInfo["chart"]?.colorSet[0],
      },
    ],
  };

  const stackedBarContent = {
    multiCol: true,
    data: [
      {
        category: {
          label: "Choco",
          labelSize: 27,
        },
        items: [
          {
            id: 1234,
            value: 2,
            color: themeInfo["chart"]?.colorSet[0],
          },
          {
            id: 2343,
            value: 1,
            color: themeInfo["chart"]?.colorSet[1],
          },
          {
            id: 3465,
            value: 3,
            color: themeInfo["chart"]?.colorSet[2],
          },
          {
            id: 4848,
            value: 3,
            color: themeInfo["chart"]?.colorSet[3],
          },
          {
            id: 5765,
            value: 3,
            color: themeInfo["chart"]?.colorSet[4],
          },
        ],
      },
      {
        category: {
          label: "Smoothie",
          labelSize: 39,
        },
        items: [
          {
            id: 1234,
            value: 3,
            color: themeInfo["chart"]?.colorSet[0],
          },
          {
            id: 2343,
            value: 2,
            color: themeInfo["chart"]?.colorSet[1],
          },
          {
            id: 3465,
            value: 3,
            color: themeInfo["chart"]?.colorSet[2],
          },
          {
            id: 4848,
            value: 4,
            color: themeInfo["chart"]?.colorSet[3],
          },
          {
            id: 5765,
            value: 3,
            color: themeInfo["chart"]?.colorSet[4],
          },
        ],
      },
      {
        category: {
          label: "Ice-cream",
          labelSize: 44,
        },
        items: [
          {
            id: 1234,
            value: 4,
            color: themeInfo["chart"]?.colorSet[0],
          },
          {
            id: 2343,
            value: 4,
            color: themeInfo["chart"]?.colorSet[1],
          },
          {
            id: 3465,
            value: 3,
            color: themeInfo["chart"]?.colorSet[2],
          },
          {
            id: 4848,
            value: 1,
            color: themeInfo["chart"]?.colorSet[3],
          },
          {
            id: 5765,
            value: 2,
            color: themeInfo["chart"]?.colorSet[4],
          },
        ],
      },
      {
        category: {
          label: "Sugar",
          labelSize: 25,
        },
        items: [
          {
            id: 1234,
            value: 2,
            color: themeInfo["chart"]?.colorSet[0],
          },
          {
            id: 2343,
            value: 2,
            color: themeInfo["chart"]?.colorSet[1],
          },
          {
            id: 3465,
            value: 3,
            color: themeInfo["chart"]?.colorSet[2],
          },
          {
            id: 4848,
            value: 3,
            color: themeInfo["chart"]?.colorSet[3],
          },
          {
            id: 5765,
            value: 2,
            color: themeInfo["chart"]?.colorSet[4],
          },
        ],
      },
    ],
  };

  const tableStyles = {
    header: {
      background: themeInfo["table"]?.headerBg,
      color: themeInfo["table"]?.headerColor,
      borderBottom: `1px solid ${themeInfo["table"]?.borderColor}`,
    },
    col: {
      color: themeInfo["table"]?.color,
      borderRight: `1px solid ${themeInfo["table"]?.borderColor}`,
      lineHeight: themeInfo["table"]?.lineHeight,
      letterSpacing: themeInfo["table"]?.letterSpacing,
    },
    table: {
      border: `1px solid ${themeInfo["table"]?.borderColor}`,
      background: themeInfo["table"]?.background,
      fontFamily: themeInfo["table"]?.fontFamily,
      stripeBackground: themeInfo["table"]?.stripeBackground,
      stripeColor: themeInfo["table"]?.stripeColor,
    },
    row: {
      background: themeInfo["table"]?.background,
      borderBottom: `1px solid ${themeInfo["table"]?.borderColor}`,
    },
  };

  let displayFields = null;

  if (selectedTab === "Text") {
    displayFields = (
      <>
        <TextItem>
          <Name style={{color: themeInfo["heading"]?.color1}}>Header</Name>
          <p
            style={{
              color: themeInfo["heading"]?.color1,
              fontSize: 52,
              letterSpacing: themeInfo["heading"]?.letterSpacing,
              lineHeight: themeInfo["heading"]?.lineHeight,
              fontWeight: 'bold',
              fontFamily: themeInfo["heading"]?.fontFamily,
              width: "100%",
            }}
          >
            This is a header
          </p>
        </TextItem>

        <TextItem>
          <Name style={{color: themeInfo["subheading"]?.color1}}>Subheader</Name>
          <p
            style={{
              color: themeInfo["subheading"]?.color1,
              fontSize: 36,
              letterSpacing: themeInfo["subheading"]?.letterSpacing,
              lineHeight: themeInfo["subheading"]?.lineHeight,
              fontWeight: 'bold',
              fontFamily: themeInfo["subheading"]?.fontFamily,
              width: "100%",
            }}
          >
            This is a subheader
          </p>
        </TextItem>

        <TextItem>
          <Name style={{color: themeInfo["normalText"]?.color1}}>Normal Text</Name>
          <p
            style={{
              color: themeInfo["normalText"]?.color1,
              fontSize: 20,
              letterSpacing: themeInfo["normalText"]?.letterSpacing,
              lineHeight: themeInfo["normalText"]?.lineHeight,
              fontWeight: 'normal',
              fontFamily: themeInfo["normalText"]?.fontFamily,
              width: "25%",
            }}
          >
            This is a paragraph section where you display information about any relevant details. Use this space to convey key messages.
          </p>
        </TextItem>

        <TextItem>
          <Name style={{color: themeInfo["smallText"]?.color1}}>Small Text</Name>
          <p
            style={{
              color: themeInfo["smallText"]?.color1,
              fontSize: 16,
              letterSpacing: themeInfo["smallText"]?.letterSpacing,
              lineHeight: themeInfo["smallText"]?.lineHeight,
              fontWeight: 'normal',
              fontFamily: themeInfo["smallText"]?.fontFamily,
              width: "25%",
            }}
          >
            This is a small text section where you display less significant information. Use this space to convey trivial messages.
          </p>
        </TextItem>

        {/* <TextItem>
          <Name style={{color: themeInfo["button"]?.color1}}>Button</Name>
          <button
            style={{
              color: themeInfo["button"]?.color1,
              fontSize: 16,
              letterSpacing: themeInfo["button"]?.letterSpacing,
              lineHeight: themeInfo["button"]?.lineHeight,
              fontWeight: 'normal',
              fontFamily: themeInfo["button"]?.fontFamily,
              width: 120,
              height: 34,
              padding: 4,
              background: themeInfo["button"]?.background,
              borderRadius: 6,
              border: "none",
              // boxShadow: `3px 3px 1px ${themeInfo["button"]?.shadowColor}`,
            }}
          >
            Button
          </button>
        </TextItem> */}

        <TextItem>
          <Name style={{color: themeInfo["listContent"]?.color1}}>List Content</Name>
          <OrderListWithNumbers
            color={themeInfo["listContentWithBackground"]?.color1}
            background={themeInfo["listContentWithBackground"]?.bg1}
            style={{
              marginRight: 32,
              fontSize: 22,
              fontFamily: themeInfo["listContentWithBackground"]?.fontFamily,
              lineHeight: themeInfo["listContentWithBackground"]?.lineHeight,
            }}
            fontSize={22} 
            lineHeight={themeInfo["listContentWithBackground"]?.lineHeight}
          >
            <li 
              style={{
                color: themeInfo["listContentWithBackground"]?.color1,
              }}
            >
              <span>List One</span>
            </li>
            <li 
              style={{
                color: themeInfo["listContentWithBackground"]?.color1,
              }}
            >
              <span>List Two</span>
            </li>
          </OrderListWithNumbers>

          <div
            style={{
              color: themeInfo["listContent"]?.color1,
              fontSize: 22,
              letterSpacing: themeInfo["listContent"]?.letterSpacing,
              lineHeight: themeInfo["listContent"]?.lineHeight,
              opacity: themeInfo["listContent"]?.opacity,
              fontStyle: themeInfo["listContent"]?.fontStyle,
              fontWeight: 'normal',
              textAlign: themeInfo["listContent"]?.textAlign,
              fontFamily: themeInfo["listContent"]?.fontFamily,
            }}
          >
            <ol>
              <li>
                <span>List One</span>
              </li>
              <li>
                <span>List Two</span>
              </li>
            </ol>
          </div>
        </TextItem>

        <TextItem>
          <Name style={{color: themeInfo["bulletContent"]?.color1}}>Bullet Content</Name>
          <BulletContent
            bulletColor={themeInfo["bulletContent"]?.bulletColor}
            style={{
              color: themeInfo["bulletContent"].color1,
              fontSize: 22,
              letterSpacing: themeInfo["bulletContent"]?.letterSpacing,
              lineHeight: themeInfo["bulletContent"]?.lineHeight,
              opacity: themeInfo["bulletContent"]?.opacity,
              fontStyle: themeInfo["bulletContent"]?.fontStyle,
              fontWeight: 'normal',
              textAlign: themeInfo["bulletContent"]?.textAlign,
              fontFamily: themeInfo["bulletContent"]?.fontFamily,
            }}
          >
            <ul>
              <li>
                <span>Bullet One</span>
              </li>
              <li>
                <span>Bullet Two</span>
              </li>
            </ul>
          </BulletContent>
        </TextItem>
      </>
    );
  } else if (selectedTab === "Image") {
    const {shadowColor, overlayColor} = imageStyles;
    let selectedShadowValue = `${shadowColor} 5px 10px 7px`;
    displayFields = (
      <Item>
        <div style={{position: "relative"}}>
          <img
            src="https://images.unsplash.com/photo-1459755486867-b55449bb39ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
            alt=""
            style={{
              maxWidth: "100%",
              height: 280,
              MozBoxShadow: selectedShadowValue,
              WebkitBoxShadow: selectedShadowValue,
              boxShadow: selectedShadowValue,
            }}
          />
          <Overlay
            type="half"
            color={
              overlayColor ? tinycolor(overlayColor).toRgbString() : undefined
            }
            degree={180}
          />
        </div>
      </Item>
    );
  } else if (selectedTab === "Shape") {
    displayFields = (
      <Item style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex"}}>
          <div style={{marginRight: 22}}>
            <SelectedShape
              size={{
                w: width > 1400 ? 250 : 150,
                h: width > 1400 ? 250 : 150,
              }}
              shape="circle"
              styles={{...shapeStyles}}
              index="123341a"
            />
          </div>
          <div>
            <SelectedShape
              size={{
                w: width > 1400 ? 350 : 200,
                h: width > 1400 ? 250 : 150,
              }}
              shape="rectangle"
              styles={{
                ...shapeStyles,
                enableStroke: true,
                strokeWidth: 5,
                enableGradient: true,
              }}
              tempScale={1}
              index="3441435b"
            />
          </div>
        </div>
        <div style={{marginTop: 62}}>
          <SelectedShape
            size={{
              w: 80,
              h: 18,
            }}
            shape="line"
            styles={{
              ...shapeStyles,
              color1: themeInfo["shape"]?.lineColor,
              color2: themeInfo["shape"]?.lineColor,
              lineType: "line-with-right-arrow",
            }}
            index="4641735b"
          />
        </div>
      </Item>
    );
  } else if (selectedTab === "Chart") {
    displayFields = (
      <Item style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: "flex"}}>
          <div style={{marginLeft: 16}}>
            <ThemeBuilderBarChart
              size={{w: 320, h: 300}}
              styles={{...barChartStyles}}
              content={barContent}
            />
          </div>
          <div style={{marginLeft: 46}}>
            <ThemeBuilderStackedBar
              size={{w: 320, h: 300}}
              styles={{...stackedBarChartStyles}}
              content={stackedBarContent}
            />
          </div>
        </div>
        <ChartLegend style={{color: themeInfo["chart"]?.legendColor}}>
          <div className="chart-legend-theme-builder">
            <div style={{background: themeInfo["chart"]?.colorSet[0]}} />
            Ice-cream
          </div>
          <div className="chart-legend-theme-builder">
          <div style={{background: themeInfo["chart"]?.colorSet[1]}} />
            Jelly</div>
          <div className="chart-legend-theme-builder">
          <div style={{background: themeInfo["chart"]?.colorSet[2]}} />
            Sugar
          </div>
          <div className="chart-legend-theme-builder">
          <div style={{background: themeInfo["chart"]?.colorSet[3]}} />
            Smoothie
          </div>
        </ChartLegend>
      </Item>
    );
  } else if (selectedTab === "Background") {
    displayFields = (
      <Item style={{display: "flex", flexDirection: "column"}}></Item>
    );
  } else if (selectedTab === "Table") {
    const {header, col, table, row} = tableStyles;
    displayFields = (
      <Item style={{display: "flex", flexDirection: "column"}}>
        <h2 style={{marginBottom: 12, color: "#232323"}}>Without Stripe</h2>
        <Table
          style={{...table, marginBottom: 32}}
          header={{
            type: table.headerType,
            background: table.headerBgColor,
            color: table.headerColor,
          }}
          stripe={{
            enabled: false,
            background: table?.stripeBackground,
            color: table?.stripeColor,
          }}
          background={row.background}
        >
          <tbody>
            <tr>
              <th style={{...col, ...header}}>Header 1</th>
              <th style={{...col, ...header}}>Header 2</th>
              <th style={{...col, ...header}}>Header 3</th>
            </tr>
            <tr style={{...row}}>
              <td style={{...col}}>Value 1</td>
              <td style={{...col}}>Value 2</td>
              <td style={{...col}}>Value 3</td>
            </tr>
            <tr style={{...row}}>
              <td style={{...col}}>Value 1</td>
              <td style={{...col}}>Value 2</td>
              <td style={{...col}}>Value 3</td>
            </tr>
            <tr style={{...row}}>
              <td style={{...col}}>Value 1</td>
              <td style={{...col}}>Value 2</td>
              <td style={{...col}}>Value 3</td>
            </tr>
          </tbody>
        </Table>

        <h2 style={{margin: "12px 0", color: "#232323"}}>
          With Stripe Enabled
        </h2>
        <Table
          style={{...table}}
          header={{
            type: table.headerType,
            background: table.headerBgColor,
            color: table.headerColor,
          }}
          stripe={{
            enabled: true,
            background: table?.stripeBackground,
            color: table?.stripeColor,
          }}
        >
          <tbody>
            <tr>
              <th style={{...col, ...header}}>Header 1</th>
              <th style={{...col, ...header}}>Header 2</th>
              <th style={{...col, ...header}}>Header 3</th>
            </tr>
            <tr style={{...row}}>
              <td style={{...col}}>Value 1</td>
              <td style={{...col}}>Value 2</td>
              <td style={{...col}}>Value 3</td>
            </tr>
            <tr style={{...row}}>
              <td style={{...col}}>Value 1</td>
              <td style={{...col}}>Value 2</td>
              <td style={{...col}}>Value 3</td>
            </tr>
            <tr style={{...row}}>
              <td style={{...col}}>Value 1</td>
              <td style={{...col}}>Value 2</td>
              <td style={{...col}}>Value 3</td>
            </tr>
          </tbody>
        </Table>
      </Item>
    );
  } else if (selectedTab === "Video") {
    displayFields = (
      <Item style={{display: "flex", flexDirection: "column"}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 500,
            height: 300,
            borderRadius: 12,
            background: "#ff9a00",
            border: `3px solid ${themeInfo["video"]?.borderColor}`,
          }}
        >
          <div style={{width: 50, height: 50, fill: "#df4c4c"}}>
            <svg viewBox="0 0 24 24">
              <path d="M8 5v14l11-7z"></path>
            </svg>
          </div>
        </div>
      </Item>
    );
  }

  return (
    <Layout width={width}>
      <Canvas
        style={{
          ...backgroundStyle,
        }}
      >
        {displayFields}
      </Canvas>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  background: ${({theme}) => theme.deskSecondaryBg};
  height: ${window.innerHeight - 61}px;
  width: ${({width}) => width}px;
  @media only screen and (min-width: 850px) {
    width: ${({width}) => width - 340}px;
  }
`;

const Canvas = styled.div`
  width: 100%;
  background: #dedede;
  padding: 8px 8px 8px 35px;
  overflow-y: scroll;
  // height: 620px;
`;

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  tr {
    font-size: 1rem;
    color: #7a3d3d;
    th {
      width: 200px;
      height: 20px;
      text-align: left;
    }
    td {
      width: 200px;
      height: 20px;
    }
    &:nth-child(odd) {
      td {
        &:first-child {
          color: ${({stripe, header}) =>
            header.type === "top-left" || header.type === "left"
              ? `${header.color} !important`
              : stripe.enabled
              ? `${stripe.color} !important`
              : undefined};
          background: ${({stripe, header}) =>
            header.type === "top-left" || header.type === "left"
              ? `${header.background} !important`
              : stripe.enabled
              ? `${stripe.background} !important`
              : undefined};
        }
        color: ${({stripe}) =>
          stripe.enabled ? `${stripe.color} !important` : undefined};
        background: ${({stripe}) =>
          stripe.enabled ? `${stripe.background} !important` : undefined};
      }
    }
  }
`;

const Item = styled.div`
  padding: 32px 0 16px;
  display: flex;
`;

const TextItem = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0;
  font-size: 1.5rem;
`;

const OrderListWithNumbers = styled.ol`
  padding: 0;
  margin: 0;
  counter-reset: oat-all-counter;
  margin-left: 42px;
  li {
    list-style-type: none;
    position: relative;
    padding-left: 3ch;
    counter-increment: oat-all-counter;
    &:before {
      display: inline-block;
      content: counter(oat-all-counter);
      --size: 2.5ch;
      position: absolute;
      top: ${({fontSize, lineHeight}) =>
      Math.floor((fontSize * lineHeight) / 2 - fontSize / 2) - 4}px;
      left: 0ch;
      width: var(--size);
      height: var(--size);
      line-height: var(--size);
      text-align: center;
      color: ${({color}) => color};
      background: ${({background}) => background};
      border-radius: 50%;
      font-size: 1.5rem;
    }
  }
`;

const Name = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  min-width: 170px;
  color: #262626;
`;

const BulletContent = styled.div`
  li {
    list-style-type: none;
  }
  li::before {
    display: inline-block;
    ${({bulletColor}) =>
      css`
        content: "${'\u25CF'}"; // "\\2022"; // "✅";
        width: 2ch;
        color: ${bulletColor};
    `} 
  }
`

const ChartLegend = styled.div`
  margin-top: 100px;
  display: flex;
  font-size: 15px;
  .chart-legend-theme-builder {
    display: flex;
    align-items: center;
    margin-right: 12px; 
    > div {
      width: 10px;
      height: 10px;
      margin-right: 6px; 
    }
  }
`

const mapStateToProps = (state) => {
  return {
    themeInfo: selectThemeBuilder(state.designTemplate),
  };
};
export default connect(mapStateToProps, null)(ThemeCanvas);
