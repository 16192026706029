import React, {useState} from "react";
import styled, {css} from "styled-components";
import AnimateIn from "../../../oat-ui/animation/Animate";
import {
  defaultMaxWidthDesktop,
  defaultMaxWidthLaptop,
  layoutPadding,
} from "../layoutVariables";
import {WrapHeader} from "./FeatureList";
import {HomeHeader} from "./FeaturesShowcase2";
import {black2} from "../../../oat-color-variables";

const themes = [
  {
    themeName: "Ocean Blue",
    colors: [
      "rgb(183 150 219)",
      "rgb(236 177 255)",
      "rgb(255 235 235)",
      "rgb(154 207 255)",
      "rgb(179 251 150)",
    ],
  },
  {
    themeName: "Rosy Red",
    colors: [
      "rgb(147 217 141)",
      "rgb(184 245 188)",
      "rgb(171 206 169)",
      "rgb(249 241 148)",
      "rgb(218 159 225)",
    ],
  },
  {
    themeName: "Cloudy Sky",
    colors: [
      "rgb(255 204 106)",
      "rgb(255 229 179)",
      "rgb(218 167 167)",
      "rgb(145 141 226)",
      "rgb(243 193 157)",
    ],
  },
];

const DisplayThemeItem = ({theme, onSelect}) => {
  const handleClick = () => {
    onSelect(theme);
  };
  return (
    <ThemeItem onClick={handleClick}>
      <p>{theme.themeName}</p>
      <div className="homepg-theme-panel-colors">
        {theme.colors.map((color) => (
          <div style={{background: color}}></div>
        ))}
      </div>
    </ThemeItem>
  );
};
const TemplateThemes = () => {
  const [selectedImage, setSelectedImage] = useState("Ocean Blue");
  const [imgHeight, setImgHeight] = useState(0);

  const onChangeImage = (value) => {
    setSelectedImage(value);
  };

  const getImageHeight = (e) => {
    setImgHeight(e.target.height);
  };

  const onSelectTheme = (theme) => {
    setSelectedImage(theme.themeName);
  };

  return (
    <Layout>
      <Wrapper>
        <WrapHeader>
          <AnimateIn style={{flexDirection: "column"}}>
            <HomeHeader>Template Themes</HomeHeader>
          </AnimateIn>
        </WrapHeader>

        <WrapContent>
          <AnimateIn>
            <Content>
              <div className="homepg-theme__content-description">
                Easily color your templates with themes
              </div>

              {/* <ThemeList>
                {tags &&
                  tags.map((tag, i) => (
                    <div key={i} onClick={() => onChangeImage(tag)}>
                      {tag}
                    </div>
                  ))}
              </ThemeList> */}
              <ThemePanel>
                {themes.map((theme) => (
                  <DisplayThemeItem theme={theme} onSelect={onSelectTheme} />
                ))}
              </ThemePanel>
            </Content>
          </AnimateIn>
          <AnimateIn flex={2}>
            <WrapImage style={{height: imgHeight}}>
              <Image
                style={{
                  opacity: selectedImage === "Ocean Blue" ? 1 : 0,
                  zIndex: selectedImage === "Ocean Blue" ? 3 : 1,
                }}
                alt=""
                onLoad={getImageHeight}
                src="https://dnaeh50kru9rd.cloudfront.net/home-page/template+3+colors/template-purple-theme-1200.png"
                loading="lazy"
              />
              <Image
                style={{
                  opacity: selectedImage === "Rosy Red" ? 1 : 0,
                  zIndex: selectedImage === "Rosy Red" ? 3 : 1,
                }}
                alt=""
                src="https://dnaeh50kru9rd.cloudfront.net/home-page/template+3+colors/template-green-theme-1200.png"
                loading="lazy"
              />
              <Image
                style={{
                  opacity: selectedImage === "Cloudy Sky" ? 1 : 0,
                  zIndex: selectedImage === "Cloudy Sky" ? 3 : 1,
                }}
                alt=""
                src="https://dnaeh50kru9rd.cloudfront.net/home-page/template+3+colors/template-yellow-theme-1200.png"
                loading="lazy"
              />
            </WrapImage>
          </AnimateIn>
        </WrapContent>
      </Wrapper>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3.4em;
  box-sizing: border-box;
  @media only screen and (min-width: 601px) {
    padding: 2.8rem ${layoutPadding.desktop};
    margin-top: 42px;
  }
  @media only screen and (max-width: 600px) {
    padding: 2.2em ${layoutPadding.mobile};
    margin: 26px 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: ${defaultMaxWidthLaptop}px;
  @media only screen and (min-width: 1000px) and (max-width: 1179px) {
    max-width: 94%;
  }
  @media only screen and (min-width: 1180px) and (max-width: 1500px) {
    max-width: ${defaultMaxWidthLaptop}px;
  }
  @media only screen and (min-width: 1501px) {
    max-width: ${defaultMaxWidthDesktop}px;
  }
`;

const WrapImage = styled.div`
  position: relative;
  width: 100%;
  overflow: visible;
  border-radius: 12px;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  box-sizing: border-box;
  object-fit: contain;
  position: absolute;
  transition: 0.3s ease-in;
  box-shadow: 0px 0px 0px 1px #e0e0e0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  .homepg-theme__content-description {
    margin-bottom: 14px;
    font-size: 1rem;
  }
`;

const styleCss = css`
  padding: 4px 18px;
  margin: 10px 12px 0 2px;
  border-radius: 16px;
  cursor: pointer;
  @media only screen and (max-width: 849px) {
    flex-direction: row;
    margin: 10px 12px 22px 2px;
    flex: 0 0 auto;
  }
`;

const ThemeList = styled.div`
  display: flex;
  flex: 0 0 auto;
  font-size: 0.92rem;
  flex-direction: row;
  div {
    &:nth-child(1) {
      border: 1px solid #6e83ff;
      background: #e7e7fc;
      align-self: flex-start;
      margin-bottom: 8px;
      ${styleCss};
    }
    &:nth-child(2) {
      border: 1px solid #38aeff;
      background: #d1edff;
      align-self: flex-start;
      margin-bottom: 8px;
      ${styleCss};
    }
    &:nth-child(3) {
      border: 1px solid #fb9ece;
      background: #fce4f5;
      align-self: flex-start;
      margin-bottom: 8px;
      ${styleCss};
    }
  }
  @media only screen and (max-width: 849px) {
    width: ${window.innerWidth}px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const WrapContent = styled.div`
  display: flex;
  @media only screen and (min-width: 1080px) {
    margin-top: 24px;
  }
  @media only screen and (max-width: 1079px) {
    flex-direction: column;
  }
`;

const ThemePanel = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1079px) {
    flex-direction: row;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ThemeItem = styled.div`
  display: flex;
  jsutify-content: space-between;
  align-items: center;
  border: 1px solid #e5e5e5;
  margin: 16px 0 16px 6px;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
  color: ${black2};
  p {
    font-size: 1.05rem;
    margin-right: 18px;
  }
  .homepg-theme-panel-colors {
    display: flex;
    flex-wrap: wrap;
    border-radius: 6px;
    overflow: hidden;
    height: 30px;
    div {
      font-size: 0.84rem;
      width: 30px;
      height: 30px;
      @media only screen and (max-width: 849px) {
        width: 24px;
        height: 24px;
      }
    }
  }
  @media only screen and (max-width: 849px) {
    flex: 0 0 auto;
    margin: 16px 12px 28px 0px;
    p {
      font-size: 0.98rem;
      margin-right: 18px;
    }
    .homepg-theme-panel-colors {
      height: 24px;
      div {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export default TemplateThemes;
